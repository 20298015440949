import React, { Component } from 'react'
import SermonsSeriesView from '../SermonsSeriesView'
import SermonsListView from '../SermonsListView'
import { withRouter } from 'react-router-dom'
import { withSizes } from '@oos-studio/website-common-components'
import deepmerge from 'deepmerge'
import { gqlConnect } from 'react-connect-graphql-beta'
import DocumentMeta from 'react-document-meta'
import { getMeta } from '../../utils/defaultMeta'
import { connect } from 'react-redux'
import Footer from '../../footer/Footer'
import LoadMoreButton from '../../common/LoadMoreButton'
import {
  getAllSermonSeries,
  getLatestSermons,
  getSelectedSeries,
} from '../actions'
import PassageFilter from '../PassageFilter'
import SpeakerFilter from '../SpeakerFilter'
import SermonFilterToggle from '../SermonFilterToggle'
import DateFilter from '../DateFilter'

class Sermons extends Component {
  state = {
    type: 1,
    activeType: 0,
    itemLimit: 10,
    loadIncrement: 10,
    channelEnd: false,
    listEnd: false,
    gridEnd: false,
    shouldLoadMore: true,
    mobile: false,
    keepTopPadding: false,
    fetchingChannel: false,
    toggle: false,
    loadingMore: false,
  }

  async componentDidMount() {
    console.log('WORKFLOW - TEST')

    const { itemLimit } = this.state
    const { handleResize, onToggleType } = this
    const {
      getSelectedSeries,
      getAllSermonSeries,
      getLatestSermons,
      allSermonSeries,
      latestSermons,
      isSeries,
      isLatest,
      isGrid,
      history,
    } = this.props

    if (document.URL[-1] === 't') {
      this.setState({
        type: 1,
      })
    }

    if (window.innerWidth < 1250) {
      this.setState({
        mobile: true,
      })
    }

    if (window.innerWidth >= 1040) {
      this.setState({
        keepTopPadding: true,
      })
    }

    if (document.URL[document.URL.length - 1] === 't') {
      this.setState({
        type: 1,
      })
    } else {
      this.setState({
        type: 0,
      })
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('toggle', onToggleType)

    if (isSeries) {
      const urlPieces = history.location.pathname.split('/')
      const id = urlPieces[urlPieces.length - 1]
      const seriesName = allSermonSeries.data?.filter(
        (series) => series.id === id,
      )[0]?.name
      await getSelectedSeries(id, itemLimit, seriesName)
    }
    if (isGrid) {
      if (allSermonSeries.total <= 0) {
        await getLatestSermons(itemLimit)
        await getAllSermonSeries(itemLimit)
      }
    }
    if (isLatest) {
      if (latestSermons.total <= 0) {
        await getLatestSermons(itemLimit)
      }
    }
  }

  onToggleType = (newType) => {
    this.setState({
      activeType: newType,
    })
  }

  handleResize = () => {
    const { mobile, keepTopPadding } = this.state

    if (window.innerWidth < 1250 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1250 && mobile) {
      this.setState({
        mobile: false,
      })
    }

    if (window.innerWidth < 1040 && keepTopPadding) {
      this.setState({
        keepTopPadding: false,
      })
    }
    if (window.innerWidth >= 1040 && !keepTopPadding) {
      this.setState({
        keepTopPadding: true,
      })
    }
  }

  loadMore = async () => {
    const {
      isSeries,
      isGrid,
      isLatest,
      getSelectedSeries,
      getAllSermonSeries,
      getLatestSermons,
      history,
      selectedChapter,
      selectedBook,
      selectedSpeaker,
      selectedDay,
      selectedYear,
      selectedMonth,
    } = this.props
    const { itemLimit, loadIncrement } = this.state

    const newLimit = itemLimit + loadIncrement

    this.setState({
      loadingMore: true,
    })

    if (isSeries) {
      const urlPieces = history.location.pathname.split('/')
      const id = urlPieces[urlPieces.length - 1]

      await getSelectedSeries(id, newLimit)
    }

    if (isGrid) {
      await getAllSermonSeries(newLimit)
    }

    if (isLatest) {
      if (selectedChapter || selectedBook || selectedSpeaker) {
        await getLatestSermons(
          newLimit,
          true,
          selectedBook,
          selectedChapter,
          selectedSpeaker,
        )
      } else {
        await getLatestSermons(newLimit)
      }
    }

    this.setState({
      loadingMore: false,
      itemLimit: newLimit,
    })
  }

  render() {
    const {
      isNative,
      getStyle,
      isSeries,
      isGrid,
      isLatest,
      history,
      fetching,
      selectedSeries,
      latestSermons,
      allSermonSeries,
      canLoadMoreSeries,
      canLoadMoreChannels,
      canLoadMoreLatest,
    } = this.props
    const { data, speakers } = this.props.gql
    const { mobile, keepTopPadding, loadingMore, type } = this.state
    const { loadMore, onToggleType } = this

    styles.mobile.top = {
      ...styles.mobile.top,
      paddingTop: isNative ? 35 : 134,
    }

    const _styles = mobile ? deepmerge(styles, styles.mobile) : styles

    return (
      <DocumentMeta
        {...getMeta(
          isLatest ? 'Latest Sermons' : selectedSeries?.name || 'Sermons',
        )}>
        <React.Fragment>
          <div
            style={getStyle({
              ..._styles.backgroundBlock,
              sm: {
                height: isNative ? 255 : 375,
              },
            })}
          />
          <div
            style={getStyle({
              ..._styles.top,
              paddingTop: keepTopPadding ? 169 : _styles.top.paddingTop,
              marginBottom: isSeries ? 25 : 13,
            })}>
            {isSeries && !fetching && (
              <div style={_styles.seriesImgHeader}>
                <img
                  alt={'seriesHeader'}
                  src={selectedSeries?.image || data.defaultImage[0]?.url}
                  style={styles.seriesImg}
                />
              </div>
            )}
            {window.isNativeApp && isSeries ? null : (
              <React.Fragment>
                <div style={getStyle(_styles.header)}>
                  {isSeries ? `Series: ${selectedSeries?.name}` : 'Sermons'}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SermonFilterToggle
                    onSwitch={(type) => onToggleType(type)}
                    history={history}
                    type={type}
                  />
                </div>
              </React.Fragment>
            )}
            {isLatest && (
              <div style={getStyle(_styles.filterWrapper)}>
                <SpeakerFilter speakers={speakers} />
                <PassageFilter />
                <DateFilter />
              </div>
            )}
          </div>
          <div style={isLatest ? { marginTop: -30 } : { marginTop: 20 }}>
            {isGrid &&
            allSermonSeries.total > 0 &&
            (!fetching ||
              (loadingMore && isLatest && latestSermons.total > 0)) ? (
              <SermonsSeriesView
                defaultImage={data.defaultImage[0]?.url}
                channels={allSermonSeries.data}
                latestSermons={latestSermons.videos}
              />
            ) : isSeries && selectedSeries.total > 0 ? (
              <SermonsListView isSeries series={selectedSeries.videos} />
            ) : (
              isLatest &&
              latestSermons.total > 0 && (
                <SermonsListView
                  isSeries={false}
                  series={latestSermons.videos}
                />
              )
            )}
          </div>
          {isLatest && (canLoadMoreLatest || fetching) && (
            <LoadMoreButton loading={fetching} loadMore={loadMore} />
          )}
          {isGrid && (canLoadMoreChannels || fetching) && (
            <LoadMoreButton loading={fetching} loadMore={loadMore} />
          )}
          {isSeries && (canLoadMoreSeries || fetching) && (
            <LoadMoreButton loading={fetching} loadMore={loadMore} />
          )}
          <Footer />
        </React.Fragment>
      </DocumentMeta>
    )
  }
}

const styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 500,
    position: 'absolute',
    zIndex: -1,
  },
  content: {},
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1250,
    paddingLeft: 25,
    paddingRight: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 169,
    whiteSpace: 'nowrap',
  },
  seriesImgHeader: {
    position: 'relative',
    height: 0,
    width: '100%',
    paddingBottom: '57%',
    marginTop: 30,
    overflow: 'hidden',
    marginBottom: 35,
  },
  seriesImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderWidth: 0,
    objectFit: 'cover',
  },
  header: {
    fontSize: 45,
    color: '#6A5B5D',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    whiteSpace: 'normal',
    xs: {
      fontSize: 35,
    },
  },
  filter: {
    display: 'flex',
    color: '#6A5B5D',
    fontSize: 18,
  },
  filterWrapper: {
    marginTop: 35,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    xs: {
      flexDirection: 'column',
    },
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    link: {
      color: '#65A3BE',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  mobile: {
    seriesImgHeader: {
      // marginBottom: 100,
      marginTop: 15,
    },
    top: {
      maxWidth: 800,
      paddingTop: window.isNativeApp ? 35 : 134,
      sm: {
        paddingLeft: 35,
        paddingRight: 35,
      },
      xxs: {
        //paddingRight: 20,
      },
    },
  },
}

const mapStateToProps = (state) => {
  return {
    isNative: state.app.isNative,
    latestSermons: state.sermons.latestSermons,
    allSermonSeries: state.sermons.allSermonSeries,
    selectedSeries: state.sermons.selectedSeries,
    fetching: state.sermons.fetching,
    selectedBook: state.sermons.selectedBook,
    selectedChapter: state.sermons.selectedChapter,
    selectedDay: state.sermons.selectedDay,
    selectedMonth: state.sermons.selectedMonth,
    selectedYear: state.sermons.selectedYear,
    filterValue: state.sermons.filterValue,
    selectedSpeaker: state.sermons.selectedSpeaker,
    canLoadMoreSeries: state.sermons.canLoadMoreSeries,
    canLoadMoreChannels: state.sermons.canLoadMoreChannels,
    canLoadMoreLatest: state.sermons.canLoadMoreLatest,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestSermons: (
      itemLimit,
      useFilter,
      filterBook,
      filterChapter,
      selectedSpeaker,
    ) =>
      dispatch(
        getLatestSermons(
          itemLimit,
          useFilter,
          filterBook,
          filterChapter,
          selectedSpeaker,
        ),
      ),
    getSelectedSeries: (id, itemLimit, seriesName) =>
      dispatch(getSelectedSeries(id, itemLimit, seriesName)),
    getAllSermonSeries: (itemLimit) => dispatch(getAllSermonSeries(itemLimit)),
  }
}

export default gqlConnect('sermonGlobal')(
  withRouter(withSizes(connect(mapStateToProps, mapDispatchToProps)(Sermons))),
)
